import Web3 from "web3";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { set, onValue, ref } from "firebase/database";

import multy from "../../contracts/multysig";

import ERC20ICO from "../../contracts/ERC20ICO";
import { db } from "../../firebase";
import { Col, Row } from "react-bootstrap";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const web3 = new Web3(window.ethereum);

function TokenICO() {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(
    "Plz Connect Your Wallet",
  );
  const [tokenBalance, setTokenBalance] = useState(0);
  const [network, setNetwork] = useState("");
  const [loading, setLoading] = useState(true);

  const [addressArr, setAddressArr] = useState([]);

  const [tokenAddress, setTokenAddress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [totalClaimCount, setTotalClaimCount] = useState(0);
  const [firstClaimDate, setFirstClaimDate] = useState(0);

  //const [name, setName] = useState("");
  // const [decimals, setDecimals] = useState(0);
  // const [initialSupply, setInitialSupply] = useState(0);

  const { ethereum } = window;

  const REACT_APP_CHAIN_ID = 361;
  const REACT_APP_CHAIN_ID_Test = 365;

  function isMobileDevice() {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  }

  const connectWallet = async () => {
    if (isMobileDevice() && !ethereum) {
      window.open(
        `https://metamask.app.link/dapp/${
          window.location.origin.split("//")[1]
        }`,
      );
    }

    if (!ethereum) {
      setErrorMessage("install metamask  /b install metamask");
      window.open("https://metamask.io/download.html", "_blank");

      return;
    }

    //const provider = new ethers.providers.Web3Provider(ethereum);
    const provider = new Web3(ethereum);
    const netWork = await provider.eth.getChainId();
    console.log(netWork);

    switch (netWork) {
      case 361:
        console.log("This is Theta mainnet");
        setNetwork("Theta (TFUEL) Mainnet");
        break;
      case 365:
        console.log("This is Theta Testnet network.");
        setNetwork("Theta (TFUEL)  Testnet");

        break;
      default:
        console.log("unknown");
        setNetwork("unknown");
    }

    try {
      setLoading(true);

      const result = await ethereum.request({
        method: "eth_requestAccounts",
      });

      const balance = await provider.eth.getBalance(ethereum.selectedAddress);

      console.log("DD" + balance);
      setDefaultAccount(result[0]);
      setTokenBalance(Number(web3.utils.fromWei(balance, "ether")).toFixed(4));
      setErrorMessage("");
    } catch (error) {
      const errors = {
        "-32002": error.message,
        4001: "You have rejected the request.",
      };
      setErrorMessage(errors[error.code]);
      setLoading(false);

      console.log(error);
    }
  };

  const updateNetWork = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
          },
        ],
      });

      if (chainId) {
        window.location.reload();
        await connectWallet("updateNetWork");
      }
    } catch (error) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Theta (TFUEL) Mainnet",
              chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
              rpcUrls: ["https://eth-rpc-api-testnet.thetatoken.org/rpc"],
              nativeCurrency: {
                name: "TFUEL",
                symbol: "TFUEL",
                decimals: 18,
              },
              blockExplorerUrls: ["https://explorer.thetatoken.org/"],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
      const errors = {
        4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
      };
      setLoading(false);
      console.log(error, errors[error.code]);
    }
  };

  const BSCTestnetNetWork = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
          },
        ],
      });

      if (chainId) {
        window.location.reload();
        await connectWallet("updateNetWork");
      }
    } catch (error) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Theta (TFUEL) Testnet",
              chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
              rpcUrls: ["https://eth-rpc-api-testnet.thetatoken.org/rpc"],
              nativeCurrency: {
                name: "TFUEL",
                symbol: "TFUEL",
                decimals: 18,
              },
              blockExplorerUrls: ["https://testnet-explorer.thetatoken.org/"],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
      const errors = {
        4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
      };
      setLoading(false);
      console.log(error, errors[error.code]);
    }
  };

  // function goTokenMBAdmin(){
  //     navigate( '/TokenMBAdmin')
  // }

  const x = (aps) => {
    // navigate( '/TokenCapAdmin',
    // {state:
    // 	{ data: aps}
    // })
  };

  const deployContractMulty = () => {
    const contract = new web3.eth.Contract(multy.Abi2);
    contract
      .deploy({
        data: multy.Data2,
        //CAP
        arguments: [
          [
            "0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2",
            "0x4B20993Bc481177ec7E8f571ceCaE8A9e22C02db",
          ],
          2,
        ],
        //["0x880730f4f1b7ab045ea428c238Ea4a182f9Ac558","0xc60a2823C51Fc0FBA7c8F804B7b97236821d831A"]
        //remix
        //["0xdD870fA1b7C4700F2BD7f44238821C26f7392148","0x583031D1113aD414F02576BD6afaBfb302140225","0x4B0897b0513fdC7C541B6d9D7E929C4e5364D2dB","0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2","0x4B20993Bc481177ec7E8f571ceCaE8A9e22C02db"]
        //metamask
        //["0x793F8da04eeb08fdc91CCAe77730093f4b51faC3","0x8D591d3c819Ad328D9fe8A3B4aAB147378Cc8083","0xca0e9eEc3b9Bb809e23E42C6116ec79D99E855a7","0xEF8601dB886884aa11Ff76aAc1177DDB3bee2514","0x784c263b03ac1e818B2b57245C950Afd750e9741"]
        //0x793F8da04eeb08fdc91CCAe77730093f4b51faC3,0x8D591d3c819Ad328D9fe8A3B4aAB147378Cc8083,0xca0e9eEc3b9Bb809e23E42C6116ec79D99E855a7,0xEF8601dB886884aa11Ff76aAc1177DDB3bee2514,0x784c263b03ac1e818B2b57245C950Afd750e9741
      })
      .send({
        from: defaultAccount,
      })
      .then(function (newContractInstance) {
        console.log(newContractInstance.options.address);
        console.log(newContractInstance.options);
        console.log(newContractInstance);

        // set(ref(db, 'users/' + defaultAccount + '/bnb/beb20ICO/' + newContractInstance.options.address ), {
        // 	Address: newContractInstance.options.address,
        // 	AddressToken:tokenAddress,
        // 	Duration:duration,
        // 	TotalClaimCount:totalClaimCount,
        // 	FirstClaimDate:firstClaimDate

        // });
        // window.location.reload();
      });
  };

  const deployContract = () => {
    const contract = new web3.eth.Contract(ERC20ICO.Abi);
    contract
      .deploy({
        data: ERC20ICO.Data,
        //CAP
        arguments: [duration, totalClaimCount, firstClaimDate],
      })
      .send({
        from: defaultAccount,
      })
      .then(function (newContractInstance) {
        console.log(newContractInstance.options.address);
        console.log(newContractInstance.options);
        console.log(newContractInstance);

        set(
          ref(
            db,
            "users/" +
              defaultAccount +
              "/theta/tnt20ICO/" +
              newContractInstance.options.address,
          ),
          {
            Address: newContractInstance.options.address,
            AddressToken: tokenAddress,
            Duration: duration,
            TotalClaimCount: totalClaimCount,
            FirstClaimDate: firstClaimDate,
          },
        );
        window.location.reload();
      });
  };

  const readData = () => {
    console.log("FFF");
    const arra = [];
    const query = ref(db, "users/" + defaultAccount + "/theta/tnt20Cap");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          console.log(project);
          arra.push(project);
          console.log(arra);
        });
        //setAddressArr(addressArr => [...addressArr, {Address : arra.Address  , Decimals : arra.Decimals, Name : arra.Name  ,Supply:arra.Supply , Symbol : arra.Symbol}])
        setAddressArr(arra);
        console.log(addressArr);
      }

      console.log("FFF2222");
    });
  };

  useEffect(() => {
    console.log("start");
    connectWallet();
    readData();
  }, [defaultAccount]);

  useEffect(() => {
    if (!ethereum) return;

    ethereum.on("accountsChanged", async (acc) => {
      if (acc.length === 0) {
        ///setWeb3(initWeb3State);
        setErrorMessage("");

        return;
      } else {
        setLoading(true);
        await connectWallet(acc);
      }
    });

    ethereum.on("disconnect", () => {
      setErrorMessage("");
      //setWeb3(initWeb3State);
    });

    return () => {
      ethereum.removeAllListeners();
    };
  }, []);

  return (
    <>
      <Row className="justify-content-center ">
        <Col sm={8}>
          <div className="mt-5">
            <Row className="justify-content-center">
              <Col sm={12}>
                <h3>Theta token sale contract (PRC-20)</h3>
                <h2>with instant tokens distribution (approval model)</h2>
                <h2>(fixed parameters)</h2>
                <p>
                  This is a simple token sale contract with fixed parameters.
                  Contract will send tokens in exchange of Theta instantly,
                  Theta is also sent instantly to your wallet.
                  <br></br>
                  Buyers can purchase tokens by sending Theta to contract
                  address or using user interface (you will be able to download
                  a simple draft UI with main functions).
                  <br></br>
                  Contract correctly processes changes and decimals and
                  automatically stops selling tokens once hard cap is filled.
                  <br></br>
                  You can use it with any (TNT-20) token.
                </p>
                <h2>(Mainnet and Testnet available)</h2>
              </Col>
              <Col sm={12}>
                <ConnectButton />
              </Col>
            </Row>

            <div className="row mt-10 justify-content-center">
              <div className="col-md-6">
                <input
                  className="form-control w-100 mt-3"
                  onChange={(e) => {
                    setTokenAddress(e.target.value);
                  }}
                  placeholder="Token Address"></input>
              </div>
              <div className="col-md-6">
                <input
                  className="form-control w-100 mt-3"
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                  placeholder="Duration"></input>
              </div>
              <div className="col-md-6">
                <input
                  className="form-control w-100 mt-3"
                  onChange={(e) => {
                    setTotalClaimCount(e.target.value);
                  }}
                  placeholder="Total Claim Count"></input>
              </div>
              <div className="col-md-6">
                <input
                  className="form-control w-100 mt-3"
                  onChange={(e) => {
                    setFirstClaimDate(e.target.value);
                  }}
                  placeholder="First Claim Date"></input>
              </div>
            </div>

            <h6 className="mt-3 input-with-value">
              Created tokens will be sent to this address{" "}
              <input
                className="mx-1 sm-input"
                readOnly
                value={defaultAccount}
                placeholder="Send to address"></input>{" "}
              (your address by default)
            </h6>
            <button className="btn-primary mt-3" onClick={deployContractMulty}>
              Create token
            </button>
            <hr className="mt-4" />
            <br></br>
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center mb-5">
        <Col lg={10} xl={8} className="table-overflow-scroll">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Address</th>
                <th>Name</th>
                <th>Symbol</th>
                <th>Supply</th>
                <th>Initial Supply</th>
                <th>Decimals</th>
                <th>Admin</th>
              </tr>
            </thead>

            <tbody id="strHtml">
              {addressArr.map((ape, index) => (
                <tr>
                  <td>{index}</td>

                  <td>{ape.Address}</td>
                  <td>{ape.Name}</td>
                  <td>{ape.Symbol}</td>
                  <td>{ape.Supply}</td>
                  <td>{ape.InitialSupply}</td>
                  <td>{ape.Decimals}</td>
                  <td>
                    <button onClick={(event) => x(ape, event)}>admin</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
}

export default TokenICO;

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { Routes, Route } from "react-router-dom";

import Home from "./Home";
import Polygon from "./pages/Polygon";
import Ethereum from "./pages/Ethereum";
import Binance from "./pages/Binance";
import Theta from "./pages/Theta";

//BNB
import Token from "./pages/BNB/Token";
import TokenMB from "./pages/BNB/TokenMB";
import TokenMBAdmin from "./pages/BNB/TokenMBAdmin";
import TokenCap from "./pages/BNB/TokenCap";
import TokenCapAdmin from "./pages/BNB/TokenCapAdmin";
import TokenICO from "./pages/BNB/TokenICO";
import TokenMS from "./pages/BNB/TokenMultiSig";
import TokenMultiSigAdmin from "./pages/BNB/TokenMultiSigAdmin";
import TokenAirdrop from "./pages/BNB/TokenAirdrop";
import TokenAirdropAdmin from "./pages/BNB/TokenAirdropAdmin";

//POLYGON

import PToken from "./pages/MATIC/Token";
import PTokenMB from "./pages/MATIC/TokenMB";
import PTokenMBAdmin from "./pages/MATIC/TokenMBAdmin";
import PTokenCap from "./pages/MATIC/TokenCap";
import PTokenCapAdmin from "./pages/MATIC/TokenCapAdmin";
import PTokenICO from "./pages/MATIC/TokenICO";
import PTokenMS from "./pages/MATIC/TokenMultiSig";
import PTokenMultiSigAdmin from "./pages/MATIC/TokenMultiSigAdmin";
import PTokenAirdrop from "./pages/MATIC/TokenAirdrop";
import PTokenAirdropAdmin from "./pages/MATIC/TokenAirdropAdmin";

//THETA

import TToken from "./pages/TFUEL/Token";
import TTokenMB from "./pages/TFUEL/TokenMB";
import TTokenMBAdmin from "./pages/TFUEL/TokenMBAdmin";
import TTokenCap from "./pages/TFUEL/TokenCap";
import TTokenCapAdmin from "./pages/TFUEL/TokenCapAdmin";
import TTokenICO from "./pages/TFUEL/TokenICO";
import TTokenMS from "./pages/TFUEL/TokenMultiSig";
import TTokenMultiSigAdmin from "./pages/TFUEL/TokenMultiSigAdmin";
import TTokenAirdrop from "./pages/TFUEL/TokenAirdrop";
import TTokenAirdropAdmin from "./pages/TFUEL/TokenAirdropAdmin";
import TCoinMultiSig from "./pages/TFUEL/CoinMultiSig";
import CoinMultiSigAdmin from "./pages/TFUEL/CoinMultiSigAdmin";
import TTokenAirdropMultsig from "./pages/TFUEL/TokenAirdropMultsig";
import TokenAirdropMultiSigAdmin from "./pages/TFUEL/TokenAirdropMultiSigAdmin";
import TMultiSig from "./pages/TFUEL/MultiSig";
import TMultiSigAdmin from "./pages/TFUEL/MultiSigAdmin";
import { Container } from "react-bootstrap";
import Navbar from "./components/Navbar/Navbar";
import { AppContextProvider } from "./context";

function App() {
  return (
    <>
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/polygon"
          element={
            <AppContextProvider chain="polygon">
              <Polygon />
            </AppContextProvider>
          }
        />
        <Route
          path="/binance"
          element={
            <AppContextProvider chain="bsc">
              <Binance />
            </AppContextProvider>
          }
        />
        <Route
          path="/theta"
          element={
            <AppContextProvider chain="theta">
              <Theta />
            </AppContextProvider>
          }
        />

        <Route path="/ethereum" element={<Ethereum />} />

        <Route
          path="/token"
          element={
            <AppContextProvider chain="bsc">
              <Token />
            </AppContextProvider>
          }
        />
        <Route
          path="/tokenMB"
          element={
            <AppContextProvider chain="bsc">
              <TokenMB />
            </AppContextProvider>
          }
        />
        <Route
          path="/tokenMBAdmin"
          element={
            <AppContextProvider chain="bsc">
              <TokenMBAdmin />
            </AppContextProvider>
          }
        />
        <Route
          path="/tokenCap"
          element={
            <AppContextProvider chain="bsc">
              <TokenCap />
            </AppContextProvider>
          }
        />
        <Route
          path="/tokenCapAdmin"
          element={
            <AppContextProvider chain="bsc">
              <TokenCapAdmin />
            </AppContextProvider>
          }
        />
        <Route
          path="/tokenICO"
          element={
            <AppContextProvider chain="bsc">
              <TokenICO />
            </AppContextProvider>
          }
        />
        <Route
          path="/tokenMS"
          element={
            <AppContextProvider chain="bsc">
              <TokenMS />
            </AppContextProvider>
          }
        />
        <Route
          path="/tokenMultiSigAdmin"
          element={
            <AppContextProvider chain="bsc">
              <TokenMultiSigAdmin />
            </AppContextProvider>
          }
        />
        <Route
          path="/tokenAirdrop"
          element={
            <AppContextProvider chain="bsc">
              <TokenAirdrop />
            </AppContextProvider>
          }
        />
        <Route
          path="/tokenAirdropAdmin"
          element={
            <AppContextProvider chain="bsc">
              <TokenAirdropAdmin />
            </AppContextProvider>
          }
        />

        <Route
          path="/ptoken"
          element={
            <AppContextProvider chain="polygon">
              <PToken />{" "}
            </AppContextProvider>
          }
        />
        <Route
          path="/ptokenMB"
          element={
            <AppContextProvider chain="polygon">
              <PTokenMB />{" "}
            </AppContextProvider>
          }
        />
        <Route
          path="/ptokenMBAdmin"
          element={
            <AppContextProvider chain="polygon">
              <PTokenMBAdmin />{" "}
            </AppContextProvider>
          }
        />
        <Route
          path="/ptokenCap"
          element={
            <AppContextProvider chain="polygon">
              <PTokenCap />{" "}
            </AppContextProvider>
          }
        />
        <Route
          path="/ptokenCapAdmin"
          element={
            <AppContextProvider chain="polygon">
              <PTokenCapAdmin />{" "}
            </AppContextProvider>
          }
        />
        <Route
          path="/ptokenICO"
          element={
            <AppContextProvider chain="polygon">
              <PTokenICO />{" "}
            </AppContextProvider>
          }
        />
        <Route
          path="/ptokenMS"
          element={
            <AppContextProvider chain="polygon">
              <PTokenMS />{" "}
            </AppContextProvider>
          }
        />
        <Route
          path="/ptokenMultiSigAdmin"
          element={
            <AppContextProvider chain="polygon">
              <PTokenMultiSigAdmin />{" "}
            </AppContextProvider>
          }
        />
        <Route
          path="/ptokenAirdrop"
          element={
            <AppContextProvider chain="polygon">
              <PTokenAirdrop />{" "}
            </AppContextProvider>
          }
        />
        <Route
          path="/ptokenAirdropAdmin"
          element={
            <AppContextProvider chain="polygon">
              <PTokenAirdropAdmin />{" "}
            </AppContextProvider>
          }
        />

        <Route
          path="/ttoken"
          element={
            <AppContextProvider chain="theta">
              <TToken />
            </AppContextProvider>
          }
        />
        <Route
          path="/ttokenMB"
          element={
            <AppContextProvider chain="theta">
              <TTokenMB />
            </AppContextProvider>
          }
        />
        <Route
          path="/ttokenMBAdmin"
          element={
            <AppContextProvider chain="theta">
              <TTokenMBAdmin />
            </AppContextProvider>
          }
        />
        <Route
          path="/ttokenCap"
          element={
            <AppContextProvider chain="theta">
              <TTokenCap />
            </AppContextProvider>
          }
        />
        <Route
          path="/ttokenCapAdmin"
          element={
            <AppContextProvider chain="theta">
              <TTokenCapAdmin />
            </AppContextProvider>
          }
        />
        <Route
          path="/ttokenICO"
          element={
            <AppContextProvider chain="theta">
              <TTokenICO />
            </AppContextProvider>
          }
        />
        <Route
          path="/ttokenMS"
          element={
            <AppContextProvider chain="theta">
              <TTokenMS />
            </AppContextProvider>
          }
        />
        <Route
          path="/ttokenMultiSigAdmin"
          element={
            <AppContextProvider chain="theta">
              <TTokenMultiSigAdmin />
            </AppContextProvider>
          }
        />
        <Route
          path="/ttokenAirdrop"
          element={
            <AppContextProvider chain="theta">
              <TTokenAirdrop />
            </AppContextProvider>
          }
        />
        <Route
          path="/ttokenAirdropAdmin"
          element={
            <AppContextProvider chain="theta">
              <TTokenAirdropAdmin />
            </AppContextProvider>
          }
        />
        <Route
          path="/tcoinMultiSig"
          element={
            <AppContextProvider chain="theta">
              <TCoinMultiSig />
            </AppContextProvider>
          }
        />
        <Route
          path="/tcoinMultiSigAdmin"
          element={
            <AppContextProvider chain="theta">
              <CoinMultiSigAdmin />
            </AppContextProvider>
          }
        />

        <Route
          path="/ttokenAirdropMultiSig"
          element={
            <AppContextProvider chain="theta">
              <TTokenAirdropMultsig />
            </AppContextProvider>
          }
        />
        <Route
          path="/ttokenAirdropMultiSigAdmin"
          element={
            <AppContextProvider chain="theta">
              <TokenAirdropMultiSigAdmin />
            </AppContextProvider>
          }
        />

        <Route
          path="/tmultiSig"
          element={
            <AppContextProvider chain="theta">
              <TMultiSig />
            </AppContextProvider>
          }
        />
        <Route
          path="/tmultiSigAdmin"
          element={
            <AppContextProvider chain="theta">
              <TMultiSigAdmin />
            </AppContextProvider>
          }
        />
      </Routes>
    </>
  );
}

export default App;

import Web3 from "web3";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { set, onValue, ref, update } from "firebase/database";
import Swal from "sweetalert2";

import CoinMS from "../../contracts/CoinMS";
//import ERC20 from '../../contracts/ERC20'

import { db } from "../../firebase";
import { Col, Row } from "react-bootstrap";

const web3 = new Web3(window.ethereum);

function CoinMultiSigAdmin() {
  const navigate = useNavigate();
  const loca = useLocation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(
    "Plz Connect Your Wallet",
  );
  const [tokenBalance, setTokenBalance] = useState(0);
  const [network, setNetwork] = useState("");
  const [loading, setLoading] = useState(true);

  const [addressArr, setAddressArr] = useState([]);

  const [address, setAddress] = useState(defaultAccount);
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [supply, setSupply] = useState(0);
  const [decimals, setDecimals] = useState(0);
  const [firstRender, setFirstRender] = useState();
  const [balance, setBalance] = useState();

  //
  const [toAddressToken, setToAddressToken] = useState();
  const [valueToken, setValueToken] = useState();

  const { ethereum } = window;

  const REACT_APP_CHAIN_ID = 361;
  const REACT_APP_CHAIN_ID_Test = 365;

  const multiSigContract = new web3.eth.Contract(
    CoinMS.Abi,
    loca.state.data.Address,
  );

  //const tokenContract = new web3.eth.Contract(ERC20.Abi, loca.state.data.AddressToken);

  function isMobileDevice() {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  }

  const connectWallet = async () => {
    if (isMobileDevice() && !ethereum) {
      window.open(
        `https://metamask.app.link/dapp/${
          window.location.origin.split("//")[1]
        }`,
      );
    }

    if (!ethereum) {
      setErrorMessage("install metamask  /b install metamask");
      window.open("https://metamask.io/download.html", "_blank");

      return;
    }

    //const provider = new ethers.providers.Web3Provider(ethereum);
    const provider = new Web3(ethereum);
    const netWork = await provider.eth.getChainId();
    console.log(netWork);

    switch (netWork) {
      case 361:
        console.log("This is Theta mainnet");
        setNetwork("Theta (TFUEL) Mainnet");
        break;
      case 365:
        console.log("This is Theta Testnet network.");
        setNetwork("Theta (TFUEL)  Testnet");

        break;
      default:
        console.log("unknown");
        setNetwork("unknown");
    }

    try {
      setLoading(true);

      const result = await ethereum.request({
        method: "eth_requestAccounts",
      });

      const balance = await provider.eth.getBalance(ethereum.selectedAddress);
      const balanceCoin = await provider.eth.getBalance(
        loca.state.data.Address,
      );

      console.log("DD" + balance);
      setDefaultAccount(result[0]);
      setTokenBalance(Number(web3.utils.fromWei(balance, "ether")).toFixed(4));
      setBalance(Number(web3.utils.fromWei(balanceCoin, "ether")).toFixed(4));
      setErrorMessage("");
    } catch (error) {
      const errors = {
        "-32002": error.message,
        4001: "You have rejected the request.",
      };
      setErrorMessage(errors[error.code]);
      setLoading(false);

      console.log(error);
    }
  };

  const updateNetWork = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
          },
        ],
      });

      if (chainId) {
        window.location.reload();
        await connectWallet("updateNetWork");
      }
    } catch (error) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Theta (TFUEL) Mainnet",
              chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
              rpcUrls: ["https://eth-rpc-api-testnet.thetatoken.org/rpc"],
              nativeCurrency: {
                name: "TFUEL",
                symbol: "TFUEL",
                decimals: 18,
              },
              blockExplorerUrls: ["https://explorer.thetatoken.org/"],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
      const errors = {
        4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
      };
      setLoading(false);
      console.log(error, errors[error.code]);
    }
  };

  const BSCTestnetNetWork = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
          },
        ],
      });

      if (chainId) {
        window.location.reload();
        await connectWallet("updateNetWork");
      }
    } catch (error) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Theta (TFUEL) Testnet",
              chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
              rpcUrls: ["https://eth-rpc-api-testnet.thetatoken.org/rpc"],
              nativeCurrency: {
                name: "TFUEL",
                symbol: "TFUEL",
                decimals: 18,
              },
              blockExplorerUrls: ["https://testnet-explorer.thetatoken.org/"],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
      const errors = {
        4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
      };
      setLoading(false);
      console.log(error, errors[error.code]);
    }
  };

  // const getBalance = async () =>{
  // 	await tokenContract.methods.balanceOf(loca.state.data.Address).call({from:defaultAccount}).then(balance => {
  // 		setBalance(balance)
  // 	})
  // }

  const getTransactions = async () => {
    await multiSigContract.methods
      .getAllTransactions()
      .call({ from: defaultAccount })
      .then((data) => {
        //setSupply(supply)
        console.log(data);
        setAddressArr(data);
        // Object.values(data).map((project) => {

        //     setAddressArr(addressArr => [...addressArr, {Address : project.Address  , AddressToken : project.AddressToken, NumConfirmationsRequired : project.NumConfirmationsRequired }])
        // });
      });
  };

  // const setMint = async () =>{
  // 	await tokenContract.methods.mint(mintToken).send({from:defaultAccount}).then(bool => {
  // 		console.log(bool)
  // 		tokenContract.methods.totalSupply().call({from:defaultAccount}).then(supply => {
  // 			setSupply(supply)
  // 			console.log(supply)
  // 			const postData = {
  // 				Supply:supply
  // 				};
  // 			update(ref(db, 'users/' + defaultAccount + '/bnb/beb20MS/' + loca.state.data.Address ), postData);
  // 		})
  // 		window.location.reload();
  // 	})
  // }

  const x = (aps) => {
    // navigate( '/tokenMultiSigAdmin',
    // {state:
    // 	{ data: aps}
    // })
  };

  const submitTrans = async () => {
    //wie to eth

    const valueEth = web3.utils.toWei(valueToken, "ether");
    console.log(valueEth);

    await multiSigContract.methods
      .submitTransaction(toAddressToken, valueEth, "hey iyas :)")
      .send({ from: defaultAccount })
      .then((bool) => {
        console.log(bool);
        //update(ref(db, 'users/' + defaultAccount + '/bnb/beb20MS/' + loca.state.data.Address ), postData);
        window.location.reload();
      })
      .catch((e) => {
        //swal(e+"")
        console.log(e + "");
        Swal.fire({
          icon: "error",
          title: "Oops... Not Admin",
          text: e + "",
        });
        if (e.code === 4001) {
          console.log("error :(");
        }
      });
  };

  const confirmTrans = async (index) => {
    await multiSigContract.methods
      .confirmTransaction(index)
      .send({ from: defaultAccount })
      .then((bool) => {
        console.log(bool);
        //update(ref(db, 'users/' + defaultAccount + '/bnb/beb20MS/' + loca.state.data.Address ), postData);
        window.location.reload();
      })
      .catch((e) => {
        //swal(e+"")
        console.log(e + "");
        Swal.fire({
          icon: "error",
          title: "Oops... Not Admin",
          text: e + "",
        });
        if (e.code === 4001) {
          console.log("error :(");
        }
      });
  };

  const executTrans = async (index) => {
    await multiSigContract.methods
      .executeTransaction(index)
      .send({ from: defaultAccount })
      .then((bool) => {
        console.log(bool);
        //update(ref(db, 'users/' + defaultAccount + '/bnb/beb20MS/' + loca.state.data.Address ), postData);
        window.location.reload();
      })
      .catch((e) => {
        //swal(e+"")
        console.log(e + "");
        Swal.fire({
          icon: "error",
          title: "Oops... Not Admin",
          text: e + "",
        });
        if (e.code === 4001) {
          console.log("error :(");
        }
      });
  };

  const revokeTrans = async (index) => {
    await multiSigContract.methods
      .revokeConfirmation(index)
      .send({ from: defaultAccount })
      .then((bool) => {
        console.log(bool);
        //update(ref(db, 'users/' + defaultAccount + '/bnb/beb20MS/' + loca.state.data.Address ), postData);
        window.location.reload();
      })
      .catch((e) => {
        //swal(e+"")
        console.log(e + "");
        Swal.fire({
          icon: "error",
          title: "Oops... Not Admin",
          text: e + "",
        });
        if (e.code === 4001) {
          console.log("error :(");
        }
      });
  };

  useEffect(() => {
    console.log("start");
    connectWallet();
    //getBalance()
    getTransactions();
    //getSupply()

    console.log(loca.state.data.Address);
  }, [defaultAccount]);

  useEffect(() => {
    if (!ethereum) return;

    ethereum.on("accountsChanged", async (acc) => {
      if (acc.length === 0) {
        ///setWeb3(initWeb3State);
        setErrorMessage("");

        return;
      } else {
        setLoading(true);
        await connectWallet(acc);
      }
    });

    ethereum.on("disconnect", () => {
      setErrorMessage("");
      //setWeb3(initWeb3State);
    });

    return () => {
      ethereum.removeAllListeners();
    };
  }, []);

  return (
    <>
      <Row className="justify-content-center ">
        <Col sm={8}>
          <div className="mt-4">
            <h1>TFUEL Multi Sig : {loca.state.data.Address}</h1>
            <div className="d-flex align-items-center justify-content-between mt-12">
              <button className="w-32 btn-secondary" onClick={updateNetWork}>
                Add Theta Mainnet
              </button>
              <button
                className="w-32 btn-secondary"
                onClick={BSCTestnetNetWork}>
                Add Theta Testnet
              </button>
              <button className="w-32 btn-secondary" onClick={connectWallet}>
                Connect wallet
              </button>
            </div>
            <p className=" mt-5">{defaultAccount}</p>
            <hr />
            <p>The network is: {network}</p>
            <hr />
            <p>Token Balance is: {tokenBalance}</p>

            <div className="row mt-10">
              <div className="col-md-6">
                <input
                  className="form-control w-100 mt-3"
                  onChange={(e) => {
                    setToAddressToken(e.target.value);
                  }}
                  placeholder="to Address"></input>
              </div>
              <div className="col-md-6">
                <input
                  className="form-control w-100 mt-3"
                  onChange={(e) => {
                    setValueToken(e.target.value);
                  }}
                  placeholder="Value Token"></input>
              </div>
            </div>

            <button className="btn-primary mt-3" onClick={submitTrans}>
              submit Transaction
            </button>
            <hr className="mt-4" />
            <br></br>
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center mb-5">
        <Col lg={10} xl={8} className="table-overflow-scroll">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>To Address</th>
                <th>Value</th>
                <th>Num Confirmations</th>

                <th>revoke</th>
                <th>confirm</th>
                <th>execut</th>
              </tr>
            </thead>

            <tbody id="strHtml">
              {addressArr.map((ape, index) => (
                <tr>
                  <td>{index}</td>
                  <td>{ape.to}</td>
                  <td>{web3.utils.fromWei(ape.value, "ether")}</td>
                  <td>{ape.numConfirmations}</td>
                  <td>
                    <button onClick={(event) => revokeTrans(index, event)}>
                      revoke
                    </button>
                  </td>
                  <td>
                    <button onClick={(event) => confirmTrans(index, event)}>
                      confirm
                    </button>
                  </td>
                  <td>
                    <button onClick={(event) => executTrans(index, event)}>
                      execut
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
}

export default CoinMultiSigAdmin;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBqO9Lqa9sv41wcVE5C8IJpEiXF8XeIdww",
  authDomain: "dappstore-18614.firebaseapp.com",
  databaseURL: "https://dappstore-18614-default-rtdb.firebaseio.com",
  projectId: "dappstore-18614",
  storageBucket: "dappstore-18614.appspot.com",
  messagingSenderId: "482915708172",
  appId: "1:482915708172:web:324049af48ed6d631b2d29"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
//export default ERC20;
// exports.app = app;
 //exports.db = db;
// exports.module= app

export { db }

import Web3 from "web3";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { set, onValue, ref } from "firebase/database";

import multy from "../../contracts/multysig";

import ERC20MS from "../../contracts/ERC20MS";
import { db } from "../../firebase";
import { Col, Row } from "react-bootstrap";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const web3 = new Web3(window.ethereum);

function TokenMS() {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(
    "Plz Connect Your Wallet",
  );
  const [tokenBalance, setTokenBalance] = useState(0);
  const [network, setNetwork] = useState("");
  const [loading, setLoading] = useState(true);

  const [addressArr, setAddressArr] = useState([]);

  const [tokenAddress, setTokenAddress] = useState("");
  const [owners, setOwners] = useState([]);
  const [numConfirmationsRequired, setNumConfirmationsRequired] = useState(0);
  // const [firstClaimDate, setFirstClaimDate] = useState(0);

  //const [name, setName] = useState("");
  // const [decimals, setDecimals] = useState(0);
  // const [initialSupply, setInitialSupply] = useState(0);

  const { ethereum } = window;

  const REACT_APP_CHAIN_ID = 137;
  const REACT_APP_CHAIN_ID_Test = 80001;

  function isMobileDevice() {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  }

  const connectWallet = async () => {
    if (isMobileDevice() && !ethereum) {
      window.open(
        `https://metamask.app.link/dapp/${
          window.location.origin.split("//")[1]
        }`,
      );
    }

    if (!ethereum) {
      setErrorMessage("install metamask  /b install metamask");
      window.open("https://metamask.io/download.html", "_blank");

      return;
    }

    //const provider = new ethers.providers.Web3Provider(ethereum);
    const provider = new Web3(ethereum);
    const netWork = await provider.eth.getChainId();
    console.log(netWork);

    switch (netWork) {
      case 137:
        console.log("This is Polygon mainnet");
        setNetwork("Polygon (MATIC) Mainnet");
        break;
      case 80001:
        console.log("This is Polygon Testnet network.");
        setNetwork("Polygon (MATIC) Mumbai Testnet");

        break;
      default:
        console.log("unknown");
        setNetwork("unknown");
    }

    try {
      setLoading(true);

      const result = await ethereum.request({
        method: "eth_requestAccounts",
      });

      const balance = await provider.eth.getBalance(ethereum.selectedAddress);

      console.log("DD" + balance);
      setDefaultAccount(result[0]);
      setTokenBalance(Number(web3.utils.fromWei(balance, "ether")).toFixed(4));
      setErrorMessage("");
    } catch (error) {
      const errors = {
        "-32002": error.message,
        4001: "You have rejected the request.",
      };
      setErrorMessage(errors[error.code]);
      setLoading(false);

      console.log(error);
    }
  };

  const updateNetWork = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
          },
        ],
      });

      if (chainId) {
        window.location.reload();
        await connectWallet("updateNetWork");
      }
    } catch (error) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Polygon (MATIC) Mainnet",
              chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
              rpcUrls: ["https://polygon-rpc.com/"],
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
              blockExplorerUrls: ["https://polygonscan.com/"],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
      const errors = {
        4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
      };
      setLoading(false);
      console.log(error, errors[error.code]);
    }
  };

  const BSCTestnetNetWork = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
          },
        ],
      });

      if (chainId) {
        window.location.reload();
        await connectWallet("updateNetWork");
      }
    } catch (error) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Polygon (MATIC) Mumbai Testnet",
              chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
              rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
              blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
      const errors = {
        4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
      };
      setLoading(false);
      console.log(error, errors[error.code]);
    }
  };

  // function goTokenMBAdmin(){
  //     navigate( '/TokenMBAdmin')
  // }

  const x = (aps, item) => {
    navigate("/ptokenMultiSigAdmin/", { state: { data: aps } });
  };

  // const deployContractMulty = () => {

  // 	const contract = new web3.eth.Contract(ERC20MS.Abi2);
  // 	contract.deploy({
  // 		data: multy.Data2,
  //         //CAP
  // 		arguments: [["0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2","0x4B20993Bc481177ec7E8f571ceCaE8A9e22C02db"],2]
  // 		//["0x880730f4f1b7ab045ea428c238Ea4a182f9Ac558","0xc60a2823C51Fc0FBA7c8F804B7b97236821d831A"]
  // 	})
  // 	.send({
  // 		from: defaultAccount

  // 	}).then(function(newContractInstance){
  // 		console.log(newContractInstance.options.address)
  // 		console.log(newContractInstance.options)
  // 		console.log(newContractInstance)

  // 		// set(ref(db, 'users/' + defaultAccount + '/bnb/beb20ICO/' + newContractInstance.options.address ), {
  // 		// 	Address: newContractInstance.options.address,
  // 		// 	AddressToken:tokenAddress,
  // 		// 	Duration:duration,
  // 		// 	TotalClaimCount:totalClaimCount,
  // 		// 	FirstClaimDate:firstClaimDate

  // 		// });
  //         // window.location.reload();
  // 	});
  // }

  const deployContract = () => {
    var ownersArr = owners.split(",");
    console.log(ownersArr);

    const contract = new web3.eth.Contract(ERC20MS.Abi);
    contract
      .deploy({
        data: ERC20MS.Data,
        //CAP
        arguments: [ownersArr, numConfirmationsRequired, tokenAddress],
      })
      .send({
        from: defaultAccount,
      })
      .then(function (newContractInstance) {
        console.log(newContractInstance.options.address);
        console.log(newContractInstance.options);
        console.log(newContractInstance);

        set(
          ref(
            db,
            "users/" +
              defaultAccount +
              "/matic/prc20MS/" +
              newContractInstance.options.address,
          ),
          {
            Address: newContractInstance.options.address,
            AddressToken: tokenAddress,
            Owners: ownersArr,
            NumConfirmationsRequired: numConfirmationsRequired,
          },
        );
        window.location.reload();
      });
  };

  const readData = () => {
    // console.log("FFF")

    // const query = ref(db, 'users/' + defaultAccount + '/matic/prc20MS');
    // return  onValue(query, (snapshot) => {
    // 	const data = snapshot.val();
    // 	if (snapshot.exists()) {
    // 		Object.values(data).map((project) => {

    // 			setAddressArr(addressArr => [...addressArr, {Address : project.Address  , AddressToken : project.AddressToken, NumConfirmationsRequired : project.NumConfirmationsRequired }])
    // 		});
    // 	}

    // 	console.log("FFF2222")
    // });

    console.log("FFF");
    const arra = [];
    const query = ref(db, "users/" + defaultAccount + "/theta/tnt20/");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          console.log(project);
          arra.push(project);
          console.log(arra);
        });
        setAddressArr(arra);
        console.log(addressArr);
      }

      console.log("FFF2222");
    });
  };

  useEffect(() => {
    console.log("start");
    connectWallet();
    readData();
  }, [defaultAccount]);

  useEffect(() => {
    if (!ethereum) return;

    ethereum.on("accountsChanged", async (acc) => {
      if (acc.length === 0) {
        ///setWeb3(initWeb3State);
        setErrorMessage("");

        return;
      } else {
        setLoading(true);
        await connectWallet(acc);
      }
    });

    ethereum.on("disconnect", () => {
      setErrorMessage("");
      //setWeb3(initWeb3State);
    });

    return () => {
      ethereum.removeAllListeners();
    };
  }, []);

  return (
    <>
      <Row className="justify-content-center ">
        <Col sm={8}>
          <div className="mt-5">
            <Row className="justify-content-center">
              <Col sm={12}>
                <h3>PRC-20 token Multi Signature Wallets</h3>
                <h2>with instant token multi signature contract </h2>
                <h2>(fixed parameters)</h2>
                <h2>(Mainnet and Testnet available)</h2>
              </Col>
              <Col sm={12}>
                <ConnectButton />
              </Col>
            </Row>

            <div className="row mt-10 justify-content-center">
              <div className="col-md-6">
                <input
                  className="form-control w-100 mt-3"
                  onChange={(e) => {
                    setTokenAddress(e.target.value);
                  }}
                  placeholder="Token Address"></input>
              </div>
              <div className="col-md-6">
                <input
                  className="form-control w-100 mt-3"
                  onChange={(e) => {
                    setNumConfirmationsRequired(e.target.value);
                  }}
                  placeholder="Num Confirmations Required"></input>
              </div>

              <div className="col-md-12">
                <textarea
                  className="form-control textarea w-100 mt-3"
                  name="textValue"
                  type="textarea"
                  onChange={(e) => {
                    setOwners(e.target.value);
                  }}
                  placeholder="EX : [0x00 , 0x00]"
                  rows={8}
                  cols={157}></textarea>
              </div>
            </div>

            <h6 className="mt-3 input-with-value">
              Created tokens will be sent to this address{" "}
              <input
                className="mx-1 sm-input"
                readOnly
                value={defaultAccount}
                placeholder="Send to address"></input>{" "}
              (your address by default)
            </h6>
            <button className="btn-primary mt-3" onClick={deployContract}>
              Create token
            </button>
            <hr className="mt-4" />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center mb-5">
        <Col lg={10} xl={8} className="table-overflow-scroll">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Address</th>
                <th>Address Token</th>
                <th>Num Confirmations Required</th>
                <th>Admin</th>
              </tr>
            </thead>

            <tbody id="strHtml">
              {addressArr.map((ape, index) => (
                <tr>
                  <td>{index}</td>

                  <td>{ape.Address}</td>
                  <td>{ape.AddressToken}</td>
                  <td>{ape.NumConfirmationsRequired}</td>
                  <td>
                    <button onClick={(event) => x(ape, event)}>admin</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <br></br>
        </Col>
      </Row>
    </>
  );
}

export default TokenMS;

export const makeUrl = (params, url = window.location.href) => {
  let query = Object.keys(params)
    .map((k) => k + "=" + params[k])
    .join("&");
  return url + "?" + query;
};

import Web3 from "web3";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { set, onValue, ref, update } from "firebase/database";
import { Container, Row, Col } from "react-bootstrap";

import ERC20MB from "../../contracts/ERC20MB";
import { db } from "../../firebase";

const web3 = new Web3(window.ethereum);

function TokenMBAdmin() {
  const navigate = useNavigate();
  const loca = useLocation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(
    "Plz Connect Your Wallet",
  );
  const [tokenBalance, setTokenBalance] = useState(0);
  const [network, setNetwork] = useState("");
  const [loading, setLoading] = useState(true);

  const [addressArr, setAddressArr] = useState([]);

  const [address, setAddress] = useState(defaultAccount);
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [supply, setSupply] = useState(0);
  const [decimals, setDecimals] = useState(0);
  const [firstRender, setFirstRender] = useState();
  const [balance, setBalance] = useState();
  const [mintToken, setMintToken] = useState();
  const [burnToken, setBurnToken] = useState();

  const { ethereum } = window;

  const REACT_APP_CHAIN_ID = 56;
  const REACT_APP_CHAIN_ID_Test = 97;

  const tokenContract = new web3.eth.Contract(
    ERC20MB.Abi,
    loca.state.data.Address,
  );

  function isMobileDevice() {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  }

  const connectWallet = async () => {
    if (isMobileDevice() && !ethereum) {
      window.open(
        `https://metamask.app.link/dapp/${
          window.location.origin.split("//")[1]
        }`,
      );
    }

    if (!ethereum) {
      setErrorMessage("install metamask  /b install metamask");
      window.open("https://metamask.io/download.html", "_blank");

      return;
    }

    //const provider = new ethers.providers.Web3Provider(ethereum);
    const provider = new Web3(ethereum);
    const netWork = await provider.eth.getChainId();
    console.log(netWork);

    switch (netWork) {
      case 56:
        console.log("This is Binance mainnet");
        setNetwork("Binance Mainnet");
        break;
      case 97:
        console.log("This is Binance Smart Chain Testnet network.");
        setNetwork("Binance Smart Chain Testnet");

        break;
      default:
        console.log("unknown");
        setNetwork("unknown");
    }

    try {
      setLoading(true);

      const result = await ethereum.request({
        method: "eth_requestAccounts",
      });

      const balance = await provider.eth.getBalance(ethereum.selectedAddress);

      console.log("DD" + balance);
      setDefaultAccount(result[0]);
      setTokenBalance(Number(web3.utils.fromWei(balance, "ether")).toFixed(4));
      setErrorMessage("");
    } catch (error) {
      const errors = {
        "-32002": error.message,
        4001: "You have rejected the request.",
      };
      setErrorMessage(errors[error.code]);
      setLoading(false);

      console.log(error);
    }
  };

  const updateNetWork = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
          },
        ],
      });

      if (chainId) {
        window.location.reload();
        await connectWallet("updateNetWork");
      }
    } catch (error) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Binance Smart Chain Mainnet",
              chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
              rpcUrls: ["https://bsc-dataseed1.binance.org"],
              nativeCurrency: {
                name: "BNB",
                symbol: "BNB",
                decimals: 18,
              },
              blockExplorerUrls: ["https://bscscan.com"],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
      const errors = {
        4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
      };
      setLoading(false);
      console.log(error, errors[error.code]);
    }
  };

  const BSCTestnetNetWork = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
          },
        ],
      });

      if (chainId) {
        window.location.reload();
        await connectWallet("updateNetWork");
      }
    } catch (error) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Binance Smart Chain Testnet",
              chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
              rpcUrls: ["https://data-seed-prebsc-2-s1.binance.org:8545/"],
              nativeCurrency: {
                name: "BNB",
                symbol: "BNB",
                decimals: 18,
              },
              blockExplorerUrls: ["https://testnet.bscscan.com"],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
      const errors = {
        4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
      };
      setLoading(false);
      console.log(error, errors[error.code]);
    }
  };

  const getBalance = async () => {
    await tokenContract.methods
      .balanceOf(defaultAccount)
      .call({ from: defaultAccount })
      .then((balance) => {
        setBalance(balance);
      });
  };

  const getSupply = async () => {
    await tokenContract.methods
      .totalSupply()
      .call({ from: defaultAccount })
      .then((supply) => {
        setSupply(supply);
      });
  };

  const setMint = async () => {
    await tokenContract.methods
      .mint(mintToken)
      .send({ from: defaultAccount })
      .then((bool) => {
        console.log(bool);
        tokenContract.methods
          .totalSupply()
          .call({ from: defaultAccount })
          .then((supply) => {
            setSupply(supply);
            console.log(supply);
            const postData = {
              Supply: supply,
            };
            update(
              ref(
                db,
                "users/" +
                  defaultAccount +
                  "/bnb/beb20MB/" +
                  loca.state.data.Address,
              ),
              postData,
            );
          });
        window.location.reload();
      });
  };

  const setBurn = async () => {
    await tokenContract.methods
      .burn(burnToken)
      .send({ from: defaultAccount })
      .then((bool) => {
        console.log(bool);
        tokenContract.methods
          .totalSupply()
          .call({ from: defaultAccount })
          .then((supply) => {
            setSupply(supply);
            console.log(supply);
            const postData = {
              Supply: supply,
            };
            update(
              ref(
                db,
                "users/" +
                  defaultAccount +
                  "/bnb/beb20MB/" +
                  loca.state.data.Address,
              ),
              postData,
            );
          });
        window.location.reload();
      });
  };

  useEffect(() => {
    console.log("start");
    connectWallet();
    getBalance();
    getSupply();

    console.log(loca.state.data.Address);
  }, [defaultAccount]);

  useEffect(() => {
    if (!ethereum) return;

    ethereum.on("accountsChanged", async (acc) => {
      if (acc.length === 0) {
        ///setWeb3(initWeb3State);
        setErrorMessage("");

        return;
      } else {
        setLoading(true);
        await connectWallet(acc);
      }
    });

    // ethereum.on("chainChanged", async (chainId) => {
    //   if (parseInt(chainId, 16) !== Number(REACT_APP_CHAIN_ID)) {
    // 	setErrorMessage(
    // 	  "This system support Theta Chain Only /b Invalid Network"
    // 	);

    // 	setLoading(false);
    //   } else {
    // 	await updateNetWork(chainId);
    //   }
    // });

    ethereum.on("disconnect", () => {
      setErrorMessage("");
      //setWeb3(initWeb3State);
    });

    return () => {
      ethereum.removeAllListeners();
    };
  }, []);

  return (
    <Row className="justify-content-center ">
      <Col sm={8}>
        <div className="mt-4">
          {" "}
          <h3 className="text-center">Token: {loca.state.data.Address}</h3>
          <div className="d-flex align-items-center justify-content-between mt-12">
            <button className="w-32 btn-secondary" onClick={updateNetWork}>
              Add Binance Mainnet
            </button>
            <button className="w-32 btn-secondary" onClick={BSCTestnetNetWork}>
              Add Binance Testnet
            </button>
            <button className="w-32 btn-secondary" onClick={connectWallet}>
              Connect wallet
            </button>
          </div>
          <p className=" mt-5">{defaultAccount}</p>
          <hr />
          <p>The network is: {network}</p>
          <hr />
          <p>Token Balance is: {tokenBalance}</p>
          <hr />
          <p>Token name: {loca.state.data.Name} </p>
          <hr />
          <p>Token decimals: {loca.state.data.Decimals} </p>
          <hr />
          <p>Token symbol: {loca.state.data.Symbol} </p>
          <hr />
          <p>Token supply: {supply} </p>
          <hr />
          <p>Your balance: {balance} </p>
          <hr></hr>
          <div className="d-flex align-items-center justify-content-center gap-4 flex-wrap mb-5">
            <div className="input-group blue">
              <input
                min={0}
                type="number"
                id="mint"
                className="input"
                placeholder="mint tokens"
                autocomplete="off"
                onChange={(e) => {
                  setMintToken(e.target.value);
                }}
              />
              <input
                onClick={setMint}
                className="button--submit"
                value="Mint"
                type="submit"
              />
            </div>
            <div className="input-group pink ">
              <input
                min={0}
                type="number"
                id="burn"
                className="input"
                placeholder="burn tokens"
                autocomplete="off"
                onChange={(e) => {
                  setBurnToken(e.target.value);
                }}
              />
              <input
                onClick={setBurn}
                className="button--submit"
                value="Burn"
                type="submit"
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default TokenMBAdmin;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from 'react';
import { ethers, utils } from 'ethers';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import '@rainbow-me/rainbowkit/styles.css';
import { darkTheme, getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { bsc, polygon, bscTestnet, polygonMumbai } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

const theta = {
  id: 361,
  name: 'Theta Network',
  network: 'theta',
  nativeCurrency: {
    decimals: 18,
    name: 'Theta',
    symbol: 'THETA',
  },
  rpcUrls: {
    default: {
      http: ['https://eth-rpc-api.thetatoken.org/rpc'],
    },
    public: {
      http: ['https://eth-rpc-api.thetatoken.org/rpc'],
    },
  },
  blockExplorers: {
    etherscan: {
      name: 'ThetaScan',
      url: 'https://explorer.thetatoken.org/',
    },
    default: {
      name: 'ThetaScan',
      url: 'https://explorer.thetatoken.org/',
    },
  },
};

const thetaTestnet = {
  id: 365,
  name: 'Theta Testnet',
  network: 'theta',
  nativeCurrency: {
    decimals: 18,
    name: 'Theta',
    symbol: 'THETA',
  },
  rpcUrls: {
    default: {
      http: ['https://eth-rpc-api-testnet.thetatoken.org/rpc'],
    },
    public: {
      http: ['https://eth-rpc-api-testnet.thetatoken.org/rpc'],
    },
  },
  blockExplorers: {
    etherscan: {
      name: 'ThetaScan',
      url: 'https://beta-explorer.thetatoken.org/',
    },
    default: {
      name: 'ThetaScan',
      url: 'https://beta-explorer.thetatoken.org/',
    },
  },
};

const AppContext = createContext(null);
const { ethereum } = window;

const chainObj = {
  bsc: [bsc, bscTestnet],
  polygon: [polygon, polygonMumbai],
  theta: [theta, thetaTestnet],
};
// const { chains, publicClient } = configureChains(
//   Object.values(chainObj).flat()
//   , [publicProvider()]);

const AppContextProvider = ({ children, chain }) => {
  console.log(chainObj[chain], 'chainObj[chain]');
  const { chains, publicClient } = configureChains(chainObj[chain], [publicProvider()]);

  const { connectors } = getDefaultWallets({
    appName: 'Dapp Builder',
    projectId: 'YOUR_PROJECT_ID',
    chains,
  });

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  const api = useMemo(
    () => ({
      ethereum,
    }),
    [chain, ethereum],
  );

  console.log(wagmiConfig, 'tototo');

  return (
    <AppContext.Provider value={api}>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          theme={darkTheme({
            accentColor: '#9f44f7',
            accentColorForeground: 'white',
            borderRadius: 'large',
            fontStack: 'system',
            overlayBlur: 'small',
          })}
          coolMode
          chains={chains}>
          {children}
        </RainbowKitProvider>
      </WagmiConfig>
    </AppContext.Provider>
  );
};

const useApp = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp must be used within a AppContextProvider');
  }
  return context;
};

export { AppContext, AppContextProvider, useApp };

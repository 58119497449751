import Web3 from "web3";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { set, onValue, ref } from "firebase/database";
import { Container, Row, Col } from "react-bootstrap";

import ERC20 from "../../contracts/ERC20";
import { db } from "../../firebase";
import detectEthereumProvider from "@metamask/detect-provider";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const web3 = new Web3(window.ethereum);

//const ss = new db()

function Token() {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(
    "Plz Connect Your Wallet",
  );
  const [tokenBalance, setTokenBalance] = useState(0);
  const [network, setNetwork] = useState("");
  const [loading, setLoading] = useState(true);

  const apes = [];
  const [addressArr, setAddressArr] = useState([]);

  const [address, setAddress] = useState(defaultAccount);
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [supply, setSupply] = useState(0);
  const [decimals, setDecimals] = useState(0);
  const [firstRender, setFirstRender] = useState();

  const { ethereum } = window;

  const REACT_APP_CHAIN_ID = 56;
  const REACT_APP_CHAIN_ID_Test = 97;

  function isMobileDevice() {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  }

  const connectWallet = async () => {
    if (isMobileDevice() && !ethereum) {
      window.open(
        `https://metamask.app.link/dapp/${
          window.location.origin.split("//")[1]
        }`,
      );
    }

    if (!ethereum) {
      setErrorMessage("install metamask  /b install metamask");
      window.open("https://metamask.io/download.html", "_blank");

      return;
    }

    //const provider = new ethers.providers.Web3Provider(ethereum);
    const provider = new Web3(ethereum);
    const netWork = await provider.eth.getChainId();
    console.log(netWork);

    switch (netWork) {
      case 56:
        console.log("This is Binance mainnet");
        setNetwork("Binance Mainnet");
        break;
      case 97:
        console.log("This is Binance Smart Chain Testnet network.");
        setNetwork("Binance Smart Chain Testnet");

        break;
      default:
        console.log("unknown");
        setNetwork("unknown");
    }

    try {
      setLoading(true);

      const result = await ethereum.request({
        method: "eth_requestAccounts",
      });

      const balance = await provider.eth.getBalance(ethereum.selectedAddress);

      console.log("DD" + balance);
      setDefaultAccount(result[0]);
      setTokenBalance(Number(web3.utils.fromWei(balance, "ether")).toFixed(4));
      setErrorMessage("");
    } catch (error) {
      const errors = {
        "-32002": error.message,
        4001: "You have rejected the request.",
      };
      setErrorMessage(errors[error.code]);
      setLoading(false);

      console.log(error);
    }
  };

  const updateNetWork = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
          },
        ],
      });

      if (chainId) {
        window.location.reload();
        await connectWallet("updateNetWork");
      }
    } catch (error) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Binance Smart Chain Mainnet",
              chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
              rpcUrls: ["https://bsc-dataseed1.binance.org"],
              nativeCurrency: {
                name: "BNB",
                symbol: "BNB",
                decimals: 18,
              },
              blockExplorerUrls: ["https://bscscan.com"],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
      const errors = {
        4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
      };
      setLoading(false);
      console.log(error, errors[error.code]);
    }
  };

  const BSCTestnetNetWork = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
          },
        ],
      });

      if (chainId) {
        window.location.reload();
        await connectWallet("updateNetWork");
      }
    } catch (error) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Binance Smart Chain Testnet",
              chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
              rpcUrls: ["https://data-seed-prebsc-2-s1.binance.org:8545/"],
              nativeCurrency: {
                name: "BNB",
                symbol: "BNB",
                decimals: 18,
              },
              blockExplorerUrls: ["https://testnet.bscscan.com"],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
      const errors = {
        4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
      };
      setLoading(false);
      console.log(error, errors[error.code]);
    }
  };

  const addTokenToMetaMask = async (
    _tokenAddress,
    _tokenSymbol,
    _tokenDecimals,
  ) => {
    const provider = await detectEthereumProvider();

    if (provider) {
      const web3 = new Web3(provider);

      const tokenAddress = _tokenAddress; // Replace with your token's contract address
      const tokenSymbol = _tokenSymbol; // Replace with your token's symbol
      const tokenDecimals = _tokenDecimals; // Replace with your token's decimals

      try {
        const wasAdded = await provider.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
            },
          },
        });

        if (wasAdded) {
          console.log("Token added to MetaMask");
        } else {
          console.log("Token was not added");
        }
      } catch (error) {
        console.error("Error adding token to MetaMask", error);
      }
    } else {
      console.error(
        "MetaMask is not installed. Please install the MetaMask browser extension.",
      );
    }
  };

  //const x =  new ERC20()
  //console.log(ERC20.Data.object)
  //const firebase = new firebase();

  const deployContract = () => {
    //db
    //const db = firebase.db

    //console.log(db)

    // const starCountRef = ref(db, 'Acc');
    // // const starCountRef2 = ref(starCountRef, defaultAccount);
    // onValue(starCountRef, (snapshot) => {
    // const data = snapshot.val();
    // console.log(data)
    // //updateStarCount(postElement, data);
    // });

    const contract = new web3.eth.Contract(ERC20.Abi);
    contract
      .deploy({
        data: ERC20.Data,
        arguments: [name, symbol, decimals, supply, true],
      })
      .send({
        from: defaultAccount,
      })
      .then(function (newContractInstance) {
        console.log(newContractInstance.options.address);
        console.log(newContractInstance.options);
        console.log(newContractInstance);

        set(
          ref(
            db,
            "users/" +
              defaultAccount +
              "/bnb/beb20/" +
              newContractInstance.options.address,
          ),
          {
            Address: newContractInstance.options.address,
            Name: name,
            Symbol: symbol,
            Supply: supply,
            Decimals: decimals,
          },
        );

        window.location.reload();
      });
  };

  const readData = () => {
    console.log("FFF");
    const arra = [];
    const query = ref(db, "users/" + defaultAccount + "/bnb/beb20/");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          console.log(project);
          arra.push(project);
          console.log(arra);
        });
        //setAddressArr(addressArr => [...addressArr, {Address : arra.Address  , Decimals : arra.Decimals, Name : arra.Name  ,Supply:arra.Supply , Symbol : arra.Symbol}])
        setAddressArr(arra);
        console.log(addressArr);
      }

      console.log("FFF2222");
    });
  };

  useEffect(() => {
    console.log("start");
    connectWallet();
    readData();
  }, [defaultAccount]);

  useEffect(() => {
    if (!ethereum) return;

    ethereum.on("accountsChanged", async (acc) => {
      if (acc.length === 0) {
        ///setWeb3(initWeb3State);
        setErrorMessage("");

        return;
      } else {
        setLoading(true);
        await connectWallet(acc);
      }
    });

    // ethereum.on("chainChanged", async (chainId) => {
    //   if (parseInt(chainId, 16) !== Number(REACT_APP_CHAIN_ID)) {
    // 	setErrorMessage(
    // 	  "This system support Theta Chain Only /b Invalid Network"
    // 	);

    // 	setLoading(false);
    //   } else {
    // 	await updateNetWork(chainId);
    //   }
    // });

    ethereum.on("disconnect", () => {
      setErrorMessage("");
      //setWeb3(initWeb3State);
    });

    return () => {
      ethereum.removeAllListeners();
    };
  }, []);

  return (
    <>
      <Row className="justify-content-center ">
        <Col sm={8}>
          <div className="mt-5">
            <Row className="justify-content-center">
              <Col sm={12}>
                <h3>Create Binance Smart Chain (BSC) token (BEP-20)</h3>
                <h2>(Mainnet and Testnet available)</h2>{" "}
              </Col>
              <Col sm={12}>
                <ConnectButton />
              </Col>
            </Row>

            <div className="row mt-10 justify-content-center">
              <Col md={6} sm={12}>
                <input
                  className="form-control w-100 mt-3"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Name"></input>
              </Col>
              <Col md={6} sm={12}>
                <input
                  className="form-control w-100 mt-3"
                  onChange={(e) => {
                    setSymbol(e.target.value);
                  }}
                  placeholder="Symbol"></input>
              </Col>
              <Col md={6} sm={12}>
                <input
                  type="number"
                  min={1}
                  className="form-control w-100"
                  onChange={(e) => {
                    setSupply(e.target.value);
                  }}
                  placeholder="Supply (tokens quantity)"></input>
              </Col>
              <Col md={6} sm={12}>
                <input
                  type="number"
                  min={0}
                  className="form-control w-100"
                  onChange={(e) => {
                    setDecimals(e.target.value);
                  }}
                  placeholder="Decimals"></input>
              </Col>
            </div>

            <h6 className="mt-3 input-with-value">
              Created tokens will be sent to this address
              <input
                className="mx-1 sm-input"
                readOnly
                value={defaultAccount}
                placeholder="Send to address"></input>
              (your address by default)
            </h6>
            <button className="btn-primary mt-3" onClick={deployContract}>
              Create token
            </button>
            <hr className="mt-4" />
            <br></br>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center mb-5">
        <Col lg={10} xl={8} className="table-overflow-scroll">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Address</th>
                <th>Name</th>
                <th>Symbol</th>
                <th>Supply</th>
                <th>Decimals</th>
                <th>Add to MetaMask</th>
              </tr>
            </thead>

            <tbody id="strHtml">
              {addressArr.map((ape, index) => (
                <tr key={index}>
                  <td>{index}</td>

                  <td>
                    {ape.Address.slice(0, 8)}...{ape.Address.slice(-3)}
                  </td>

                  <td>{ape.Name}</td>
                  <td>{ape.Symbol}</td>
                  <td>{ape.Supply}</td>
                  <td>{ape.Decimals}</td>
                  <td>
                    <button
                      className="btn-secondary"
                      onClick={() =>
                        addTokenToMetaMask(
                          ape.Address,
                          ape.Symbol,
                          ape.Decimals,
                        )
                      }>
                      ADD
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
}

export default Token;

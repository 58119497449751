import Web3 from "web3";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const web3 = new Web3(window.ethereum);

function Polygon() {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(
    "Plz Connect Your Wallet",
  );
  const [tokenBalance, setTokenBalance] = useState(0);
  const [network, setNetwork] = useState("");
  const [loading, setLoading] = useState(true);

  const { ethereum } = window;

  const REACT_APP_CHAIN_ID = 137;
  const REACT_APP_CHAIN_ID_Test = 80001;

  function isMobileDevice() {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  }

  const connectWallet = async () => {
    if (isMobileDevice() && !ethereum) {
      window.open(
        `https://metamask.app.link/dapp/${
          window.location.origin.split("//")[1]
        }`,
      );
    }

    if (!ethereum) {
      setErrorMessage("install metamask  /b install metamask");
      window.open("https://metamask.io/download.html", "_blank");

      return;
    }

    //const provider = new ethers.providers.Web3Provider(ethereum);
    const provider = new Web3(ethereum);
    const netWork = await provider.eth.getChainId();
    console.log(netWork);

    switch (netWork) {
      case 137:
        console.log("This is Polygon mainnet");
        setNetwork("Polygon (MATIC) Mainnet");
        break;
      case 80001:
        console.log("This is Polygon Testnet network.");
        setNetwork("Polygon (MATIC) Mumbai Testnet");

        break;
      default:
        console.log("unknown");
        setNetwork("unknown");
    }

    try {
      setLoading(true);

      const result = await ethereum.request({
        method: "eth_requestAccounts",
      });

      const balance = await provider.eth.getBalance(ethereum.selectedAddress);

      console.log("DD" + balance);
      setDefaultAccount(result[0]);
      setTokenBalance(Number(web3.utils.fromWei(balance, "ether")).toFixed(4));
      setErrorMessage("");
    } catch (error) {
      const errors = {
        "-32002": error.message,
        4001: "You have rejected the request.",
      };
      setErrorMessage(errors[error.code]);
      setLoading(false);

      console.log(error);
    }
  };

  const updateNetWork = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
          },
        ],
      });

      if (chainId) {
        window.location.reload();
        await connectWallet("updateNetWork");
      }
    } catch (error) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Polygon (MATIC) Mainnet",
              chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
              rpcUrls: ["https://polygon-rpc.com/"],
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
              blockExplorerUrls: ["https://polygonscan.com/"],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
      const errors = {
        4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
      };
      setLoading(false);
      console.log(error, errors[error.code]);
    }
  };

  const BSCTestnetNetWork = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
          },
        ],
      });

      if (chainId) {
        window.location.reload();
        await connectWallet("updateNetWork");
      }
    } catch (error) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Polygon (MATIC) Mumbai Testnet",
              chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
              rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
              blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
      const errors = {
        4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
      };
      setLoading(false);
      console.log(error, errors[error.code]);
    }
  };

  function goToken() {
    navigate("/PToken");
  }

  function goTokenMB() {
    navigate("/PTokenMB");
  }

  function goTokenCap() {
    navigate("/PTokenCap");
  }
  function goTokenICO() {
    navigate("/PTokenICO");
  }

  function goTokenMS() {
    navigate("/PTokenMS");
  }

  function goTokenAirdrop() {
    navigate("/PTokenAirdrop");
  }

  useEffect(() => {
    console.log("start");
    connectWallet();
  }, []);

  useEffect(() => {
    if (!ethereum) return;

    ethereum.on("accountsChanged", async (acc) => {
      if (acc.length === 0) {
        ///setWeb3(initWeb3State);
        setErrorMessage("");

        return;
      } else {
        setLoading(true);
        await connectWallet(acc);
      }
    });

    ethereum.on("disconnect", () => {
      setErrorMessage("");
      //setWeb3(initWeb3State);
    });

    return () => {
      ethereum.removeAllListeners();
    };
  }, []);

  return (
    <Row className="justify-content-center home-left">
      <Col sm={8}>
        <div>
          <Row className="justify-content-center">
            <Col sm={12}>
              <h1>Polygon</h1>
            </Col>
            <Col sm={12}>
              <ConnectButton />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={4} md={6} sm={12}>
              <div className="list w-100 cursor-pointer" onClick={goToken}>
                <h2 className="text-2xl">PRC-20 token</h2>
                <span className="text-secondary">Fixed supply</span>
              </div>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <div className="list w-100 cursor-pointer" onClick={goTokenMB}>
                <h2 className="text-2xl">Mintable/burnable PRC-20 token</h2>
              </div>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <div className="list w-100 cursor-pointer" onClick={goTokenCap}>
                <h2 className="text-2xl">
                  Capped, mintable & burnable PRC-20 token
                </h2>
              </div>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <div className="list w-100 cursor-pointer" onClick={goTokenICO}>
                <h2 className="text-2xl">PRC-20 token sale</h2>
                <span className="text-secondary">
                  Simple token sale contract + basic UI
                </span>{" "}
              </div>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <div className="list w-100 cursor-pointer" onClick={goTokenMS}>
                <h2 className="text-2xl">
                  PRC-20 token Multi Signature Wallets
                </h2>
                <span className="text-secondary">
                  token multi signature contract
                </span>{" "}
              </div>
            </Col>

            <Col lg={4} md={6} sm={12}>
              <div
                className="list w-100 cursor-pointer"
                onClick={goTokenAirdrop}>
                <h2 className="text-2xl">PRC-20 token Airdrop</h2>
                <span className="text-secondary">
                  token Airdrop for winners contract
                </span>{" "}
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default Polygon;

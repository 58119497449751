import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "./components/Navbar/Navbar";

function Home() {
  const navigate = useNavigate();

  function goEthereum() {
    navigate("/Ethereum");
  }
  function goPolygon() {
    navigate("/polygon");
  }
  function goBinance() {
    navigate("/Binance");
  }

  function goTheta() {
    navigate("/Theta");
  }

  return (
    <>
      <Row className="justify-content-center">
        <Col sm={8}>
          <div className="home-left">
            <h1 className="home-title">
              Build Your Smart Contract <br /> Without Coding.
            </h1>
            <p className="home-p">
              All smart contracts are whitelabel with 100% your ownership just
              choose a network. <br />
              Both mainnet and testnet supported - just switch to needed
              network.
            </p>

            <Row className="d-lg-none d-flex justify-content-center">
              <Col md={6} sm={10}>
                <div
                  className="list list-first cursor-pointer"
                  onClick={goBinance}>
                  <h2 className="text-2xl">Binance Smart Chain</h2>
                  <span className="text-secondary">(BSC-BNB)</span>
                </div>
              </Col>
              <Col md={6} sm={10}>
                <div className="list cursor-pointer" onClick={goPolygon}>
                  <h2 className="text-2xl">Polygon</h2>
                  <span className="text-secondary">(MATIC)</span>
                </div>
              </Col>
              <Col md={6} sm={10}>
                <div className="list cursor-pointer" onClick={goTheta}>
                  <h2 className="text-2xl">Theta</h2>
                  <span className="text-secondary">(TFUEL)</span>
                </div>
              </Col>
              <Col md={6} sm={10}>
                <div className="list cursor-not-allowed">
                  <h2 className="text-2xl">Ethereum</h2>
                  <span className="text-secondary">coming soon...</span>
                </div>
              </Col>
            </Row>
            <Row className="d-none d-lg-flex justify-content-center gap-4">
              <Col
                className="list list-first cursor-pointer"
                lg={4}
                onClick={goBinance}>
                <h2 className="text-2xl">Binance Smart Chain</h2>
                <span className="text-secondary">(BSC-BNB)</span>
              </Col>
              <Col lg={2} className="list cursor-pointer" onClick={goPolygon}>
                <h2 className="text-2xl">Polygon</h2>
                <span className="text-secondary">(MATIC)</span>
              </Col>
              <Col lg={2} className="list cursor-pointer" onClick={goTheta}>
                <h2 className="text-2xl">Theta</h2>
                <span className="text-secondary">(TFUEL)</span>
              </Col>
              <Col lg={2} className="list cursor-not-allowed">
                <h2 className="text-2xl">Ethereum</h2>
                <span className="text-secondary">coming soon...</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default Home;

import React from "react";
import "./Navbar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, Outlet } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

const Index = () => {
  return (
    <Row className="justify-content-center">
      <Col xl={8} lg={10}>
        <Navbar expand="lg" className="navbar">
          <Container className="nav-container">
            <Link to="/" className="logo">
              DApps Builder
            </Link>
            {/* <Navbar.Brand href="/" className='logo'>DApp Store</Navbar.Brand> */}
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="nav-btn"
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Link to="/Binance">Binance Smart Chain</Link>
                <Link to="/polygon">Polygone</Link>
                <Link to="/Theta">Theta</Link>
                <Link to="/">Ethereum</Link>
                {/* <Nav.Link href="/Binance">Binance Smart Chain</Nav.Link> */}
                {/* <Nav.Link href="/polygon">Polygon</Nav.Link>
          <Nav.Link href="/Theta">Theta</Nav.Link>
          <Nav.Link href="/">Ethereum</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
            <Outlet />
          </Container>
        </Navbar>
      </Col>
    </Row>
  );
};

export default Index;

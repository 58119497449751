import Web3 from "web3";
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate , useLocation } from 'react-router-dom';
import { set , onValue, ref , update  } from "firebase/database";

import ERC20MB from '../../contracts/ERC20MB'
import {db} from "../../firebase";


const web3 = new Web3(window.ethereum)



function TokenMBAdmin(){

	const navigate = useNavigate();
	const loca = useLocation();



    const [errorMessage, setErrorMessage] = useState(null);
	const [defaultAccount, setDefaultAccount] = useState("Plz Connect Your Wallet");
	const [tokenBalance, setTokenBalance] = useState(0);
    const [network, setNetwork] = useState("");
    const [loading, setLoading] = useState(true);

	const [addressArr, setAddressArr] = useState([]);



	const [address, setAddress] = useState(defaultAccount);
	const [name, setName] = useState("");
	const [symbol, setSymbol] = useState("");
	const [supply, setSupply] = useState(0);
	const [decimals, setDecimals] = useState(0);
	const [firstRender,setFirstRender] = useState();
	const [balance , setBalance] = useState();
	const [mintToken , setMintToken ] = useState();
	const [burnToken , setBurnToken ] = useState();




	const { ethereum } = window;

	const REACT_APP_CHAIN_ID = 361
    const REACT_APP_CHAIN_ID_Test = 365



	function isMobileDevice() {
		return "ontouchstart" in window || "onmsgesturechange" in window;
	}
	
	const connectWallet = async () => {
		if (isMobileDevice() && !ethereum) {
		  window.open(
			`https://metamask.app.link/dapp/${
			  window.location.origin.split("//")[1]
			}`
		  );
		}
	
		if (!ethereum) {
		  setErrorMessage("install metamask  /b install metamask");
		  window.open("https://metamask.io/download.html", "_blank");
	
		  return;
		}
	
		//const provider = new ethers.providers.Web3Provider(ethereum);
		const provider = new Web3(ethereum)
		const netWork = await provider.eth.getChainId();
		console.log(netWork)
        

        switch (netWork) {
            case 361:
              console.log('This is Theta mainnet')
              setNetwork("Theta (TFUEL) Mainnet")
              break
            case 365:
              console.log('This is Theta Testnet network.')
              setNetwork("Theta (TFUEL)  Testnet")

              break
            default:
              console.log('unknown')
              setNetwork("unknown")
          }
	
	
	
		try {
		  setLoading(true);
	
		  const result = await ethereum.request({
			method: "eth_requestAccounts",
		  });
	
		  const balance = await provider.eth.getBalance(ethereum.selectedAddress)
	


		  console.log("DD"+balance)
		  setDefaultAccount(result[0])
		  setTokenBalance(Number(web3.utils.fromWei(balance, 'ether')).toFixed(4))
		  setErrorMessage("");
		} catch (error) {
		  const errors = {
			"-32002": error.message,
			4001: "You have rejected the request.",
		  };
		  setErrorMessage(errors[error.code]);
		  setLoading(false);
	
		  console.log(error);
		}
	};

	const updateNetWork = async (chainId) => {
		try {
		  await ethereum.request({
			method: "wallet_switchEthereumChain",
			params: [
			  {
				chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(16)}`,
			  },
			],
		  });
	
		  if (chainId) {
			window.location.reload();
			await connectWallet("updateNetWork");
		  }
		} catch (error) {
		  try {
			await ethereum.request({
			  method: "wallet_addEthereumChain",
			  params: [
				{
				  chainName: "Theta (TFUEL) Mainnet",
				  chainId: `0x${Number(REACT_APP_CHAIN_ID).toString(
					16
				  )}`,
				  rpcUrls: ["https://eth-rpc-api-testnet.thetatoken.org/rpc"],
				  nativeCurrency: {
					name: "TFUEL",
					symbol: "TFUEL",
					decimals: 18,
				  },
				  blockExplorerUrls: [
					"https://explorer.thetatoken.org/",
				  ],
				},
			  ],
			});
		  } catch (error) {
			console.log(error);
		  }
		  const errors = {
			4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
		  };
		  setLoading(false);
		  console.log(error, errors[error.code]);
		}
	};

    const BSCTestnetNetWork = async (chainId) => {
		try {
		  await ethereum.request({
			method: "wallet_switchEthereumChain",
			params: [
			  {
				chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(16)}`,
			  },
			],
		  });
	
		  if (chainId) {
			window.location.reload();
			await connectWallet("updateNetWork");
		  }
		} catch (error) {
		  try {
			await ethereum.request({
			  method: "wallet_addEthereumChain",
			  params: [
				{
				  chainName: "Theta (TFUEL) Testnet",
				  chainId: `0x${Number(REACT_APP_CHAIN_ID_Test).toString(
					16
				  )}`,
				  rpcUrls: ["https://eth-rpc-api-testnet.thetatoken.org/rpc"],
				  nativeCurrency: {
					name: "TFUEL",
					symbol: "TFUEL",
					decimals: 18,
				  },
				  blockExplorerUrls: [
					"https://testnet-explorer.thetatoken.org/",
				  ],
				},
			  ],
			});
		  } catch (error) {
			console.log(error);
		  }
		  const errors = {
			4902: "Unrecognized chain ID Try adding the chain using wallet_addEthereumChain first.",
		  };
		  setLoading(false);
		  console.log(error, errors[error.code]);
		}
	};

	const tokenContract = new web3.eth.Contract(ERC20MB.Abi, loca.state.data.Address);






	const getBalance = async () =>{
		await tokenContract.methods.balanceOf(defaultAccount).call({from:defaultAccount}).then(balance => {
			setBalance(balance)
		})
	}

	const getSupply = async () =>{
		await tokenContract.methods.totalSupply().call({from:defaultAccount}).then(supply => {
			setSupply(supply)
		})
	}

	const setMint = async () =>{
		await tokenContract.methods.mint(mintToken).send({from:defaultAccount}).then(bool => {
			console.log(bool)
			tokenContract.methods.totalSupply().call({from:defaultAccount}).then(supply => {
				setSupply(supply)
				console.log(supply)
				const postData = {
					Supply:supply
					};
				update(ref(db, 'users/' + defaultAccount + '/theta/tnt20MB/' + loca.state.data.Address ), postData);
			})
			window.location.reload();
		})
	}

	const setBurn = async () =>{
		await tokenContract.methods.burn(burnToken).send({from:defaultAccount}).then(bool => {
			console.log(bool)
			tokenContract.methods.totalSupply().call({from:defaultAccount}).then(supply => {
				setSupply(supply)
				console.log(supply)
				const postData = {
					Supply:supply
					};
				update(ref(db, 'users/' + defaultAccount + '/theta/tnt20MB/' + loca.state.data.Address ), postData);
			})
			window.location.reload();

		})
	}







	




    useEffect(() => {
		
		console.log(
			"start"
		)
		connectWallet()
		getBalance()
		getSupply()

		console.log( loca.state.data.Address)
		

		
	}, [defaultAccount]);

    useEffect(() => {
		
		if (!ethereum) return;
	
		ethereum.on("accountsChanged", async (acc) => {
		  if (acc.length === 0) {
			///setWeb3(initWeb3State);
			setErrorMessage("");
	
			return;
		  }
		   else {
			setLoading(true);
			await connectWallet(acc);
		  }
		});
		

		
	
		// ethereum.on("chainChanged", async (chainId) => {
		//   if (parseInt(chainId, 16) !== Number(REACT_APP_CHAIN_ID)) {
		// 	setErrorMessage(
		// 	  "This system support Theta Chain Only /b Invalid Network"
		// 	);
	
		// 	setLoading(false);
		//   } else {
		// 	await updateNetWork(chainId);
		//   }
		// });
	
		ethereum.on("disconnect", () => {
		  setErrorMessage("");
		  //setWeb3(initWeb3State);
		});

	
		return () => {
		  ethereum.removeAllListeners();
		};

		

		
	  }, []);


    return(
        <div>
		    <h1>Token : {loca.state.data.Address}</h1>
			<button onClick={updateNetWork}>Add theta Mainnet</button>
            <button onClick={BSCTestnetNetWork}>Add theta Testnet</button>
            <button onClick={connectWallet}>Connect wallet</button>
            <hr></hr>
			{defaultAccount}
            <hr></hr>
            The network is : {network}
            <hr></hr>
            token Polygon is :{tokenBalance}
            <hr></hr>
			<h4>Token name: {loca.state.data.Name} </h4>
			<h4>Token decimals: {loca.state.data.Decimals} </h4>
			<h4>Token symbol: {loca.state.data.Symbol} </h4>
			<h4>Token supply: {supply}   </h4>
			<h4>Your balance: {balance} </h4>

			<hr></hr>


            <input onChange={(e) => {setMintToken(e.target.value);}} placeholder='Mint'></input>
			<button onClick={setMint}>Mint</button>

            <input onChange={(e) => {setBurnToken(e.target.value);}} placeholder='Burn'></input>
            <button onClick={setBurn}>Burn</button>


		
			<hr></hr>


			<br></br>

        </div>

    )
}

export default TokenMBAdmin;


